import * as React from 'react';
import {
  blackBase,
  blue500,
  pink500,
  green500,
  yellow500,
  purple500,
  blueDark500,
  pinkDark500,
  greenDark500,
  yellowDark500,
  purpleDark500,
} from '@mentimeter/ragnar-colors';
import { withTheme } from '@mentimeter/ragnar-react';
import { Svg } from '../shared-components';
import type { IllustrationT } from './types';

type Colors = 'blue' | 'pink' | 'green' | 'yellow' | 'purple';
export interface MentiKHeartT extends IllustrationT {
  color?: Colors;
}

const colorMap: Record<Colors, string> = {
  blue: blue500,
  pink: pink500,
  green: green500,
  yellow: yellow500,
  purple: purple500,
};

const darkColorMap: Record<Colors, string> = {
  blue: blueDark500,
  pink: pinkDark500,
  green: greenDark500,
  yellow: yellowDark500,
  purple: purpleDark500,
};

function MentiKHeartArtwork({ color = 'blue' }: MentiKHeartT) {
  return (
    <Svg viewBox="0 0 250 256.26" aria-hidden="true">
      <path
        fill={colorMap[color]}
        d="M219.14,189.84a23.5,23.5,0,0,1,0,33.21L205.58,236.6,201.16,241l-11.07,11.07H24c0-.48,0-1,0-1.44a23.47,23.47,0,0,1,40.09-16.6,23,23,0,0,1-5.41-8.43A23.49,23.49,0,0,1,97.3,200.84a23.48,23.48,0,0,1,33.2-33.22l-33.2-33.2L64.08,101.2A23.48,23.48,0,1,1,97.3,68l33.2,33.2,16.61,16.62,2.83,2.83,38.25,38.25Z"
      />
      <path
        fill={darkColorMap[color]}
        d="M210.07,88.07a23.51,23.51,0,0,1,0,33.2,84.32,84.32,0,0,0-21.88,37.63l-38.25-38.25a84.38,84.38,0,0,1,15.44-21.12l11.47-11.46a23.48,23.48,0,0,1,33.22,0Z"
      />
      <path
        fill={pinkDark500}
        d="M194.06,24.5a23.46,23.46,0,0,1-6.87,16.6l-16.6,16.6L147.11,81.19,107,41.1a23.49,23.49,0,1,1,40.09-16.6,23.48,23.48,0,1,1,46.95,0Z"
      />
      <path
        fill={blackBase}
        d="M24.67,256.26H24v-4.17h0A23.62,23.62,0,0,0,24.67,256.26Z"
      />
      <path
        fill={blackBase}
        d="M226,252.09H24a23.62,23.62,0,0,0,.64,4.17H250v-4.17Z"
      />
      <rect fill={blackBase} y="252.09" width="23.99" height="4.17" />
    </Svg>
  );
}

export const MentiKHeart = withTheme(MentiKHeartArtwork);
