import {
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useState,
} from 'react';
import { gtmTrack } from '@mentimeter/google-tracking';
import { Box, Clickable, Text, Link, Collapsable } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon, GlobeIcon } from '@mentimeter/ragnar-visuals';
import { getPathnameWithoutLocale } from '@mentimeter/i18n';
import { usePathname } from '@mentimeter/next-navigation';
import { useTranslation } from 'react-i18next';
import { LOCALES, DEFAULT_LOCALE } from 'src/constants.cjs';
import { Wide } from '../ui/layout';
import { setUserLocale } from '../utils/set-user-locale';

const LANGUAGES = [
  { title: 'English', locale: 'en-US' },
  { title: 'German', locale: 'de-DE' },
  { title: 'Portuguese (Brazil)', locale: 'pt-BR' },
  { title: 'Spanish', locale: 'es-ES' },
];

export function FooterSwitchLanguage() {
  const { i18n } = useTranslation();
  const { changeLanguage } = i18n;
  const pathname = usePathname();

  const [showMenu, setShowMenu] = useState(false);
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname, LOCALES);

  const switchLanguage = useCallback(
    (nextLocale: string, title: string) => {
      setUserLocale(nextLocale);

      gtmTrack({
        event: 'choseLanguage',
        type: title,
      });

      changeLanguage(nextLocale);
    },
    [changeLanguage],
  );

  const handleClick = useCallback(
    (locale: string, title: string) => (event: MouseEvent) => {
      event.stopPropagation();

      switchLanguage(locale, title);
    },
    [switchLanguage],
  );

  const handleKeyPress = useCallback(
    (locale: string, title: string) => (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        switchLanguage(locale, title);
      }
    },
    [switchLanguage],
  );

  return (
    <Wide
      flex="1 1 auto"
      alignItems={['stretch', 'center']}
      flexDirection="column"
      mt="space4"
      mb="space8"
    >
      <Box alignItems={['stretch', 'center']}>
        <Box
          my="space1"
          alignItems="stretch"
          borderColor="text"
          borderBottomWidth="1px"
          borderStyle="solid"
          mb={showMenu ? 'space4' : 'space0'}
          extend={() => ({
            '@media screen and (min-width: 961px)': {
              borderBottomWidth: '0px',
            },
          })}
        >
          <Clickable
            onClick={() => setShowMenu(!showMenu)}
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={showMenu}
          >
            <Box flexDirection="row">
              <GlobeIcon size={3} />
              <Text fontWeight="semiBold" ml="space2">
                Choose your language
              </Text>
            </Box>
            <Box
              mt="space1"
              extend={() => ({
                transition: 'transform 0.2s ease',
                transform: showMenu ? 'rotate(-180deg)' : 'rotate(0)',
              })}
            >
              <ChevronDownIcon color="text" size={2} />
            </Box>
          </Clickable>
        </Box>
        <Collapsable show={showMenu} width="100%">
          {LANGUAGES.map(({ title, locale }) => (
            <Box mb="space2" key={locale}>
              <Link
                fontSize="100"
                color="textWeak"
                tabIndex={showMenu ? 0 : -1} // If menu is hidden, links should not be focusable
                underline={false}
                href={
                  locale === DEFAULT_LOCALE
                    ? pathnameWithoutLocale
                    : `/${locale}${pathnameWithoutLocale}`
                }
                onClick={handleClick(locale, title)}
                onKeyUp={handleKeyPress(locale, title)}
              >
                {title}
              </Link>
            </Box>
          ))}
        </Collapsable>
      </Box>
    </Wide>
  );
}
