import * as React from 'react';
import { LOCALES } from 'src/constants.cjs';
import { getPathnameWithoutLocale } from '@mentimeter/i18n';
import type { TooltipGenericT } from '@mentimeter/ragnar-ui';
import { Box } from '@mentimeter/ragnar-ui';
import { useTranslation } from 'react-i18next';
import { usePathname } from '@mentimeter/next-navigation';
import { MenuDropdown } from './MenuDropdown';
import { NavLink } from './NavLink';
import type { MenuAction } from '.';

interface PropsT {
  actions: MenuAction[];
  placement?: TooltipGenericT['placement'] | undefined;
  overviewLabel?: string | undefined;
}

export const ActionMenu = ({ actions, placement, overviewLabel }: PropsT) => {
  const pathname = usePathname();
  const pathNameWithoutLocale = getPathnameWithoutLocale(pathname, LOCALES);
  const [selectedMenu, setSelectedMenu] = React.useState<string | null>(null);
  const { t } = useTranslation('common');

  return (
    <>
      {actions.map((action) => (
        <Box as="li" mx="space2" key={action.name}>
          {action.subActions ? (
            <MenuDropdown
              action={action}
              placement={placement}
              overviewLabel={overviewLabel}
              setSelectedMenu={setSelectedMenu}
              show={selectedMenu === action.referenceId}
              openDropdownLabel={`${t('common:page_links.dropdown')} - ${
                action.name
              }`}
            />
          ) : (
            <NavLink
              href={action.href}
              active={action.href === pathNameWithoutLocale}
              onClick={() => action.onClick?.()}
              prefetch={action.prefetch ?? null}
              mr="space1"
            >
              {action.name}
            </NavLink>
          )}
        </Box>
      ))}
    </>
  );
};
